*{
    margin: 0px;
    padding: 0px;
}

div#main-content {
    padding-top: 60px;
    padding-bottom: 60px;
}

a.active_button>button {
    opacity: 1;
}

a.active_button_none>button {
    transform: scale(0.9);
    opacity: 0.5;
}